/* eslint-disable jsx-a11y/label-has-associated-control */
import SiteFooter from '../layouts/SiteFooter';
import SiteNavbar from '../layouts/SiteNavbar';
import useRouteNames from '../utils/useRouteNames';
import useStrings from '../utils/useStrings';

// ----------------------------------------------------------------------

export default function Contact() {
  return (
    <div>
      <SiteNavbar activeName={useRouteNames.contact} />
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: 'url(images/hero_bg_1.jpg)' }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8" data-aos="fade-up" data-aos-delay={400}>
              <h1 className="text-white font-weight-light text-uppercase font-weight-bold">
                {useStrings.route.contactUs}
              </h1>
              <p className="breadcrumb-custom">
                <a href={useRouteNames.home}>{useStrings.route.home}</a>{' '}
                <span className="mx-2">&gt;</span> <span>{useStrings.route.contact}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-5">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.426669357065!2d106.6885854260718!3d10.82253359260029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529d4ea1533d7%3A0x2447408992d33b76!2zMTE4IEh14buzbmggS2jGsMahbmcgQW4sIFBoxrDhu51uZyA1LCBHw7IgVuG6pXAsIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1594669525921!5m2!1sen!2s"
                width="100%"
                height="100%"
                frameBorder={0}
                allowFullScreen
                aria-hidden="false"
                // tabIndex={0}
                style={{ border: 0 }}
              />
            </div>
            <div className="col-md-5">
              <div className="p-4 mb-3 bg-white">
                <p className="mb-0 font-weight-bold">{useStrings.contact.address}</p>
                <p className="mb-4">118 Huỳnh Khương An, Phường 5, Gò Vấp, Hồ Chí Minh</p>
                <p className="mb-0 font-weight-bold">{useStrings.contact.phone}</p>
                <p className="mb-4">
                  <a href="#">0979144696</a>
                </p>
                <p className="mb-0 font-weight-bold">{useStrings.contact.hotline}</p>
                <p className="mb-4">
                  <a href="#">0367566949</a>
                </p>
                <p className="mb-0 font-weight-bold">Email</p>
                <p className="mb-4">
                  <a href="#">info.vantaihaiminh@gmail.com</a>
                </p>
                <p className="mb-0 font-weight-bold">{useStrings.contact.taxCode}</p>
                <p className="mb-0">
                  <a href="#">0315552251</a>
                </p>
              </div>
              <div className="p-4 mb-3 bg-white">
                <h3 className="h5 text-black mb-3">{useStrings.contact.moreInfo}</h3>
                <p>{useStrings.contact.content}</p>
                <p>
                  <a href="tin-tuc" className="btn btn-primary px-4 py-2 text-white">
                    {useStrings.contact.learnMore}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
