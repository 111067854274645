import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// ----------------------------------------------------------------------
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../services/firebase';
// ----------------------------------------------------------------------

DraftEditor.propTypes = {
  docId: PropTypes.string,
  content: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool
};

export default function DraftEditor({ docId, readOnly, content, onChange }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && content.length > 0) {
      const contentBlock = htmlToDraft(content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
        setLoaded(true);
      }
    }
  }, [content, loaded]);

  const uploadImageCallBack = (file) =>
    new Promise((resolve, reject) => {
      const todo = async () => {
        try {
          const storageRef = ref(storage, `posts/${docId}/${Date.now()}`);
          // 'file' comes from the Blob or File API
          const snapshot = await uploadBytes(storageRef, file);
          // Get the download URL
          const url = await getDownloadURL(snapshot.ref);
          resolve({ data: { link: url } });
        } catch (error) {
          reject(error);
        }
      };
      todo();
    });

  const onEditorStateChange = (_editorState) => {
    setEditorState(_editorState);
    onChange(draftToHtml(convertToRaw(_editorState.getCurrentContent())));
  };

  return (
    <Editor
      readOnly={readOnly}
      toolbarHidden={readOnly}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      toolbar={{
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        image: {
          uploadCallback: uploadImageCallBack,
          previewImage: true,
          alt: { present: true, mandatory: false },
          inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
        }
      }}
      editorStyle={
        readOnly
          ? {}
          : {
              border: '1px solid #F1F1F1',
              minHeight: 500,
              paddingLeft: '10px',
              paddingRight: '10px'
            }
      }
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
}
