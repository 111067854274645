// ----------------------------------------------------------------------

const useRouteNames = {
  home: '/',
  about: 'gioi-thieu',
  blog: 'tin-tuc',
  newPost: 'them-moi',
  contact: 'lien-he',
  services: 'dich-vu',
  login: 'dang-nhap',
  register: 'dang-ky',
  dashboard: 'bang-dieu-khien'
};

export default useRouteNames;
