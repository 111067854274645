// component
import useRouteNames from '../../utils/useRouteNames';
import useStrings from '../../utils/useStrings';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: useStrings.route.statistic,
    path: `/${useRouteNames.dashboard}/app`,
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: useStrings.route.blog,
    path: `/${useRouteNames.dashboard}/${useRouteNames.blog}`,
    icon: getIcon('eva:file-text-fill')
  }
];

export default sidebarConfig;
