import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  query,
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  orderBy as _orderBy,
  writeBatch
} from 'firebase/firestore';
import { ref, listAll, deleteObject } from 'firebase/storage';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
//
import { auth, db, storage } from '../../services/firebase';

import useRouteNames from '../../utils/useRouteNames';
import useStrings from '../../utils/useStrings';
import { Post } from '../../types/post';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Tiêu đề', alignRight: false },
  { id: 'status', label: 'Trạng thái', alignRight: false },
  { id: 'updatedAt', label: 'Ngày cập nhật', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function DashboardBlog() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [posts, setPosts] = useState([]);

  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterTitle, setFilterTitle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchPosts = async () => {
    try {
      const q = query(collection(db, 'posts'), _orderBy('updatedAt', 'desc'));

      const querySnapshot = await getDocs(q);
      const _posts = [];
      querySnapshot.forEach((doc) => {
        _posts.push({ ...doc.data(), docId: doc.id });
      });
      setPosts(_posts);
      setSelected([]);
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate(`/${useRouteNames.login}`);
      return;
    }

    fetchPosts();
  }, [user, loading, navigate]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = posts.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, docId) => {
    const selectedIndex = selected.indexOf(docId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, docId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByTitle = (event) => {
    setFilterTitle(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - posts.length) : 0;

  // apply filter
  const filteredUsers = posts.filter(
    (post) =>
      filterTitle.length === 0 || post.title.toLowerCase().includes(filterTitle.toLowerCase())
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleUpdateStatus = async (docId, value) => {
    try {
      const docRef = doc(db, 'posts', docId);

      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        status: value
      });
      fetchPosts();
    } catch (err) {
      console.error(err);
      alert('An error occured');
    }
  };

  const handleDeletePost = async (docId) => {
    try {
      await deleteDoc(doc(db, 'posts', docId));
      // Create a reference under which you want to list
      const listRef = ref(storage, `posts/${docId}`);

      // Find all the prefixes and items.
      const listResult = await listAll(listRef);
      listResult.items.forEach((itemRef) => {
        // Delete the file
        deleteObject(itemRef);
      });
      fetchPosts();
    } catch (err) {
      console.error(err);
      alert('An error occured');
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await Promise.all(selected.map((docId) => handleDeletePost(docId)));
      fetchPosts();
    } catch (err) {
      console.error(err);
      alert('An error occured');
    }
  };

  return (
    <Page title="Quản lý tin tức">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {useStrings.route.blog}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${useRouteNames.newPost}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {useStrings.blog.newPost}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterTitle={filterTitle}
            onFilterTitle={handleFilterByTitle}
            onDelete={handleDeleteSelected}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={posts.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { docId, id, status, title, updatedAt } = row;
                      const isItemSelected = selected.indexOf(docId) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, docId)}
                            />
                          </TableCell>
                          <TableCell width="70%">
                            <Link
                              color="textPrimary"
                              underline="hover"
                              to={id}
                              component={RouterLink}
                            >
                              {title}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(status === 'hidden' && 'error') || 'success'}
                            >
                              {status === 'active' ? 'Hiện' : 'Ẩn'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            {updatedAt.toDate().toLocaleDateString('vi-VN')}
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu
                              status={status}
                              onUpdateStatus={(value) => handleUpdateStatus(docId, value)}
                              onDelete={() => handleDeletePost(docId)}
                              onEdit={() => {
                                navigate(`/${useRouteNames.dashboard}/${useRouteNames.blog}/${id}`);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterTitle} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
