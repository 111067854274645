import useStrings from '../utils/useStrings';
import SiteFooter from '../layouts/SiteFooter';
import SiteNavbar from '../layouts/SiteNavbar';
import useRouteNames from '../utils/useRouteNames';
import useConstants from '../utils/useConstants';

// ----------------------------------------------------------------------

export default function Services() {
  return (
    <div>
      <SiteNavbar activeName={useRouteNames.services} />
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: 'url(images/hero_bg_1.jpg)' }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8" data-aos="fade-up" data-aos-delay={400}>
              <h1 className="text-white font-weight-light text-uppercase font-weight-bold">
                {useStrings.route.services}
              </h1>
              <p className="breadcrumb-custom">
                <a href={useRouteNames.home}>{useStrings.route.home}</a>{' '}
                <span className="mx-2">&gt;</span> <span>{useStrings.route.services}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section">
        <div className="container">
          <div className="row">
            {useConstants.services.map((item) => (
              <div key={item.title} className="col-md-6 col-lg-4 mb-4">
                <a href="#" className="unit-1 text-center">
                  <img
                    src={item.image}
                    alt={item.title}
                    // className="img-fluid"
                    width="100%"
                    height="300px"
                  />
                  <div className="unit-1-text">
                    <h3 className="unit-1-heading">{item.title}</h3>
                    <p className="px-5">{item.description}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="site-section bg-light">
        <div className="container">
          <div className="row align-items-stretch">
            {useConstants.services.map((item) => (
              <div key={item.title} className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4">
                    <span className={`text-primary ${item.icon}`} />
                  </div>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    {/* <p>
                    <a href="#">Learn More</a>
                  </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="site-section border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary">Testimonials</h2>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_3.jpg" alt="person_3" className="img-fluid mb-3" />
                  <p>John Smith</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_2.jpg" alt="person_2" className="img-fluid mb-3" />
                  <p>Christine Aguilar</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_4.jpg" alt="person_4" className="img-fluid mb-3" />
                  <p>Robert Spears</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_5.jpg" alt="person_5" className="img-fluid mb-3" />
                  <p>Bruce Rogers</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <SiteFooter />
    </div>
  );
}
