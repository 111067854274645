import SiteFooter from '../layouts/SiteFooter';
import SiteNavbar from '../layouts/SiteNavbar';
import useRouteNames from '../utils/useRouteNames';
import useStrings from '../utils/useStrings';

// ----------------------------------------------------------------------

export default function About() {
  return (
    <div>
      <SiteNavbar activeName={useRouteNames.about} />
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: 'url(images/hero_bg_1.jpg)' }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8" data-aos="fade-up" data-aos-delay={400}>
              <h1 className="text-white font-weight-light text-uppercase font-weight-bold">
                {useStrings.route.aboutUs}
              </h1>
              <p className="breadcrumb-custom">
                <a href={useRouteNames.home}>{useStrings.route.home}</a>{' '}
                <span className="mx-2">&gt;</span>
                <span>{useStrings.route.about}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-5 ml-auto mb-5 order-md-2" data-aos="fade">
              <img src="images/img_1.jpg" alt="img_1" className="img-fluid rounded" />
            </div>
            <div className="col-md-6 order-md-1" data-aos="fade">
              <div className="text-left pb-1 border-primary mb-4">
                <h2 className="text-primary">GIỚI THIỆU</h2>
              </div>
              <p className="mb-5">
                Với kinh nghiệm nhiều năm hoạt động trong lĩnh vực vận chuyển. Vận tải Hải Minh hiện
                đã xây dựng nên chỗ đứng vững chắc trong lòng của hàng chục ngàn khách hàng cả nước.
                Chất lượng dịch vụ tốt, giá cả hợp lý. Thái độ phục vụ tận tình và luôn lắng nghe
                mong muốn của khách hàng,… Chính là những bí quyết để Vận tải Hải Minh trở thành
                thương hiệu vận chuyển được đánh giá hàng đầu hiện nay.
              </p>
              {/* <div className="row">
                <div className="col-md-12 mb-md-5 mb-0 col-lg-6">
                  <div className="unit-4">
                    <div className="unit-4-icon mb-3 mr-4">
                      <span className="text-primary flaticon-frontal-truck" />
                    </div>
                    <div>
                      <h3>Ground Shipping</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis.
                      </p>
                      <p className="mb-0">
                        <a href="#">Learn More</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-md-5 mb-0 col-lg-6">
                  <div className="unit-4">
                    <div className="unit-4-icon mb-3 mr-4">
                      <span className="text-primary flaticon-travel" />
                    </div>
                    <div>
                      <h3>Air Freight</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis.
                      </p>
                      <p className="mb-0">
                        <a href="#">Learn More</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*  */}
              <div className="text-left pb-1 border-primary mb-4">
                <h2 className="text-primary">CON NGƯỜI VÀ LÝ TƯỞNG</h2>
              </div>
              <p>
                Bộ máy của Hải Minh được xây dựng nên từ những con người tràn đầy lòng nhiệt thành
                và đam mê. Mỗi một cá nhân là một viên gạch chắc chắn, được tuyển chọn kỹ càng.
                Trong đó, chúng tôi gắn kết với nhau bằng sự tin tưởng, bằng tinh thần trách nhiệm,
                và vì lý tưởng chung, đó là:
              </p>
              <ul className="mb-5">
                <li>
                  <p>
                    Tìm ra giải pháp vận chuyển tối ưu cho thị trường. Một giải pháp vận chuyển
                    không chỉ uy tín, chất lượng mà phải thật tiết kiệm, mang lại lợi ích tối đa cho
                    đối tác và khách hàng.
                  </p>
                </li>
                <li>
                  <p>
                    Lãnh đạo là những người có hoài bão lớn, sáng tạo và dám dấn thân thay đổi để
                    dẫn dắt doanh nghiệp theo những hướng đi tối ưu nhất.
                  </p>
                </li>
                <li>
                  <p>
                    Nhân viên là những cá nhân có kỹ năng chuyên môn, được đào tạo kỹ càng, luôn
                    cống hiến hết mình vì mục tiêu chung.
                  </p>
                </li>
              </ul>
              {/*  */}
              <div className="text-left pb-1 border-primary mb-4">
                <h2 className="text-primary">GIÁ TRỊ CỐT LÕI</h2>
              </div>
              <ol className="mb-5">
                <li>
                  <p>
                    Chất lượng sản phẩm là cốt lõi: Chúng tôi luôn nỗ lực mang đến cho khách hàng
                    những dịch vụ vận chuyển có chất lượng tốt nhất với chi phí hợp lý nhất.
                  </p>
                </li>
                <li>
                  <p>
                    Khách hàng là trọng tâm: Lợi ích của khách hàng luôn được đặt lên trên lợi ích
                    của doanh nghiệp. Sự hài lòng của khách hàng chính là động lực và mục tiêu phấn
                    đấu của chúng tôi.
                  </p>
                </li>
                <li>
                  <p>
                    Cái Tâm là gốc của thành công: Đối với mỗi công trình, từng nhân viên đảm trách
                    của Hải Minh đều dành trọn tâm huyết, hoàn thành nhiệm vụ với tinh thần trách
                    nhiệm cao nhất.
                  </p>
                </li>
                <li>
                  <p>
                    Sự tôn trọng: Chúng tôi tôn trọng khách hàng, tôn trọng tài sản của khách hàng
                    và tôn trọng mọi cam kết đề ra.
                  </p>
                </li>
                <li>
                  <p>
                    Xây dựng uy tín dài lâu: Uy tín của Hải Minh được xây dựng bằng chất lượng dịch
                    vụ, bằng sự chân thành, trung thực và thái độ phục vụ hết mình.
                  </p>
                </li>
                <li>
                  <p>
                    Luôn lịch sự và thân thiện: Là tiêu chí đặt ra trong tác phong làm việc của mỗi
                    nhân viên. Người của Hải Minh luôn nhã nhặn và lễ phép với khách hàng.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="site-section bg-image overlay"
        style={{ backgroundImage: 'url("images/hero_bg_4.jpg")' }}
      >
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary" data-aos="fade">
                How It Works
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay={100}>
              <div className="how-it-work-item">
                <span className="number">1</span>
                <div className="how-it-work-body">
                  <h2>Choose Your Service</h2>
                  <p className="mb-5">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt praesentium
                    dicta consectetur fuga neque fugit a at. Cum quod vero assumenda iusto.
                  </p>
                  <ul className="ul-check list-unstyled white">
                    <li className="text-white">Error minus sint nobis dolor</li>
                    <li className="text-white">Voluptatum porro expedita labore esse</li>
                    <li className="text-white">Voluptas unde sit pariatur earum</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay={200}>
              <div className="how-it-work-item">
                <span className="number">2</span>
                <div className="how-it-work-body">
                  <h2>Select Your Payment</h2>
                  <p className="mb-5">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt praesentium
                    dicta consectetur fuga neque fugit a at. Cum quod vero assumenda iusto.
                  </p>
                  <ul className="ul-check list-unstyled white">
                    <li className="text-white">Error minus sint nobis dolor</li>
                    <li className="text-white">Voluptatum porro expedita labore esse</li>
                    <li className="text-white">Voluptas unde sit pariatur earum</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay={300}>
              <div className="how-it-work-item">
                <span className="number">3</span>
                <div className="how-it-work-body">
                  <h2>Tracking Your Order</h2>
                  <p className="mb-5">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt praesentium
                    dicta consectetur fuga neque fugit a at. Cum quod vero assumenda iusto.
                  </p>
                  <ul className="ul-check list-unstyled white">
                    <li className="text-white">Error minus sint nobis dolor</li>
                    <li className="text-white">Voluptatum porro expedita labore esse</li>
                    <li className="text-white">Voluptas unde sit pariatur earum</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="site-section border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary" data-aos="fade">
                Our Team
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay={100}>
              <div className="person">
                <img src="images/person_2.jpg" alt="person_2" className="img-fluid rounded mb-5" />
                <h3>Christine Rooster</h3>
                <p className="position text-muted">Co-Founder, President</p>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi at consequatur unde
                  molestiae quidem provident voluptatum deleniti quo iste error eos est praesentium
                  distinctio cupiditate tempore suscipit inventore deserunt tenetur.
                </p>
                <ul className="ul-social-circle">
                  <li>
                    <a href="#">
                      <span className="icon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay={200}>
              <div className="person">
                <img src="images/person_3.jpg" alt="person_3" className="img-fluid rounded mb-5" />
                <h3>Brandon Sharp</h3>
                <p className="position text-muted">Co-Founder, COO</p>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi at consequatur unde
                  molestiae quidem provident voluptatum deleniti quo iste error eos est praesentium
                  distinctio cupiditate tempore suscipit inventore deserunt tenetur.
                </p>
                <ul className="ul-social-circle">
                  <li>
                    <a href="#">
                      <span className="icon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay={300}>
              <div className="person">
                <img src="images/person_4.jpg" alt="person_4" className="img-fluid rounded mb-5" />
                <h3>Connor Hodson</h3>
                <p className="position text-muted">Marketing</p>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi at consequatur unde
                  molestiae quidem provident voluptatum deleniti quo iste error eos est praesentium
                  distinctio cupiditate tempore suscipit inventore deserunt tenetur.
                </p>
                <ul className="ul-social-circle">
                  <li>
                    <a href="#">
                      <span className="icon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="site-section border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary">Testimonials</h2>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_3.jpg" alt="person_3" className="img-fluid mb-3" />
                  <p>John Smith</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_2.jpg" alt="person_2" className="img-fluid mb-3" />
                  <p>Christine Aguilar</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_4.jpg" alt="person_4" className="img-fluid mb-3" />
                  <p>Robert Spears</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_5.jpg" alt="person_5" className="img-fluid mb-3" />
                  <p>Bruce Rogers</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <SiteFooter />
    </div>
  );
}
