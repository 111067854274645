import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { query, collection, getDocs, where } from 'firebase/firestore';
// material
import { Card } from '@mui/material';
//
import { db } from '../services/firebase';
import SiteFooter from '../layouts/SiteFooter';
import SiteNavbar from '../layouts/SiteNavbar';
import useRouteNames from '../utils/useRouteNames';
import useStrings from '../utils/useStrings';
import DraftEditor from '../components/DraftEditor';

// ----------------------------------------------------------------------

export default function Post() {
  const navigate = useNavigate();
  const [post, setPost] = useState({});

  const fetchPost = async () => {
    try {
      const { pathname } = window.location;
      const id = pathname.substring(pathname.lastIndexOf('/') + 1);
      const q = query(
        collection(db, 'posts'),
        where('id', '==', id),
        where('status', '==', 'active')
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setPost(doc.data());
      });
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  };

  useEffect(() => {
    fetchPost();
  }, [navigate]);

  return (
    <div>
      <SiteNavbar activeName={useRouteNames.blog} />
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: 'url(images/hero_bg_1.jpg)' }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8" data-aos="fade-up" data-aos-delay={400}>
              <h1 className="text-white font-weight-light text-uppercase font-weight-bold">
                {post.title || ''}
              </h1>
              <p className="breadcrumb-custom">
                <a href={useRouteNames.home}>{useStrings.route.home}</a>{' '}
                <span className="mx-2">&gt;</span>
                <a href={`/${useRouteNames.blog}`}>{useStrings.route.blog}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section bg-light">
        <div className="container">
          <Card style={{ padding: '24px' }}>
            <DraftEditor content={post.content || ''} readOnly />
          </Card>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
