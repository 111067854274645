// ----------------------------------------------------------------------

export default function SiteFooter() {
  return (
    <footer className="">
      <div className="row text-center">
        <div className="col-md-12">
          <div className="border-top pt-4">
            <p>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              Copyright © All rights reserved | Made with{' '}
              <i className="icon-heart" aria-hidden="true" /> by{' '}
              <a href="https://colorlib.com" target="_blank" rel="noreferrer">
                Colorlib
              </a>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
