import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
//
import useConstants from '../utils/useConstants';
import Logo from '../components/Logo';
import useRouteNames from '../utils/useRouteNames';
import useStrings from '../utils/useStrings';
// ----------------------------------------------------------------------

SiteNavbar.propTypes = {
  activeName: PropTypes.string
};

export default function SiteNavbar({ activeName }) {
  const navigate = useNavigate();

  return (
    <div className="site-wrap">
      <div className="site-mobile-menu">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle" />
          </div>
        </div>
        <div className="site-mobile-menu-body" />
      </div>
      <header className="site-navbar py-3" role="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-11 col-xl-2">
              <h1 className="mb-0">
                <p className="text-white h2 mb-0">
                  <Logo sx={{ width: 80, height: 80 }} />
                </p>
              </h1>
            </div>
            <div className="col-12 col-md-10 d-none d-xl-block">
              <nav className="site-navigation position-relative text-right" role="navigation">
                <ul className="site-menu js-clone-nav mx-auto d-none d-lg-block">
                  <li className={activeName === useRouteNames.home ? 'active' : ''}>
                    <a href={useRouteNames.home}>{useStrings.route.home}</a>
                  </li>
                  <li className={activeName === useRouteNames.about ? 'active' : ''}>
                    <a href={`/${useRouteNames.about}`}>{useStrings.route.about}</a>
                  </li>
                  <li
                    className={`has-children ${
                      activeName === useRouteNames.services ? 'active' : ''
                    }`}
                  >
                    <a href={`/${useRouteNames.services}`}>{useStrings.route.services}</a>
                    <ul className="dropdown">
                      {useConstants.services.map((item) => (
                        <li key={item.title}>
                          <Link to={`/${useRouteNames.blog}`} component={RouterLink}>
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className={activeName === useRouteNames.blog ? 'active' : ''}>
                    <a href={`/${useRouteNames.blog}`}>{useStrings.route.blog}</a>
                  </li>
                  <li className={activeName === useRouteNames.contact ? 'active' : ''}>
                    <a href={`/${useRouteNames.contact}`}>{useStrings.route.contact}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div
              className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
              style={{ position: 'relative', top: 3 }}
            >
              <a href="#" className="site-menu-toggle js-menu-toggle text-white">
                <span className="icon-menu h3" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
