import { EditorState, convertToRaw } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import { stateFromHTML } from 'draft-js-import-html';

// ----------------------------------------------------------------------

export function fToSlug(str) {
  // remove accents
  const from = 'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ';
  const to = 'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(RegExp(from[i], 'gi'), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\\-]/g, '-')
    .replace(/-+/g, '-');

  return str;
}

export function fToMarkdown(html) {
  const editorState = EditorState.createWithContent(stateFromHTML(html));
  return draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
}
