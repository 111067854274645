import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  query,
  collection,
  getDocs,
  where,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  serverTimestamp
} from 'firebase/firestore';

// material
import { Stack, Container, Card, Input, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
//
import { fToSlug } from '../../utils/formatString';
import { auth, db } from '../../services/firebase';
import useRouteNames from '../../utils/useRouteNames';
import useStrings from '../../utils/useStrings';
import DraftEditor from '../../components/DraftEditor';

// ----------------------------------------------------------------------

export default function DashboardPost() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  const [docId, setDocId] = useState('');
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchPosts = async () => {
    try {
      // gen default doc id
      const docRef = doc(collection(db, 'posts'));

      const { pathname } = window.location;
      const _id = pathname.substring(pathname.lastIndexOf('/') + 1);
      if (_id === useRouteNames.newPost) {
        setDocId(docRef.id);
        return;
      }
      const q = query(collection(db, 'posts'), where('id', '==', _id));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        setDocId(docRef.id);
      } else {
        querySnapshot.forEach((doc) => {
          setDocId(doc.id);
          const post = doc.data();
          setId(post.id);
          setTitle(post.title);
          setContent(post.content);
        });
      }
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate(`/${useRouteNames.login}`);
      return;
    }

    fetchPosts();
  }, [user, loading, navigate]);

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      if (id.length === 0) {
        const id = `${fToSlug(title)}-${Date.now()}`;
        // Add a new document with a generated id
        const docRef = doc(db, 'posts', docId);
        const data = {
          id,
          title,
          content,
          status: 'active',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
        await setDoc(docRef, data, { merge: true });
      } else {
        const docRef = doc(db, 'posts', docId);
        const data = {
          title,
          content,
          updatedAt: serverTimestamp()
        };
        await updateDoc(docRef, data);
      }
      setIsSubmitting(false);
      navigate(-1);
    } catch (err) {
      setIsSubmitting(false);
      console.error(err);
      alert('An error occured');
    }
  };

  return (
    <Page title={id.length > 0 ? useStrings.blog.editPost : useStrings.blog.newPost}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {id.length > 0 ? useStrings.blog.editPost : useStrings.blog.newPost}
          </Typography>
          <LoadingButton
            disabled={title.length === 0 || content.length === 0}
            variant="contained"
            startIcon={<Iconify icon="eva:save-fill" />}
            onClick={handleSave}
            loading={isSubmitting}
          >
            {useStrings.common.save}
          </LoadingButton>
        </Stack>

        <Card style={{ padding: '24px' }}>
          <Typography sx={{ color: 'text.secondary' }}>Nhập tiêu đề</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Tiêu đề"
              value={title}
              // endAdornment={
              //   <InputAdornment position="start">
              //     <Iconify
              //       icon="eva:search-fill"
              //       sx={{ color: 'text.disabled', width: 20, height: 20 }}
              //     />
              //   </InputAdornment>
              // }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              onChange={(event) => setTitle(event.target.value)}
            />
          </Stack>
          <Typography sx={{ color: 'text.secondary', marginBottom: 1 }}>Nhập nội dung</Typography>
          {docId.length > 0 && (
            <DraftEditor
              docId={docId}
              content={content}
              onChange={(_content) => {
                setContent(_content);
              }}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
