// ----------------------------------------------------------------------

const useConstants = {
  language: {
    en: 'en',
    vi: 'vi'
  },
  services: [
    {
      icon: 'flaticon-car',
      title: 'Chuyển Nhà',
      description: `Luôn sẵn sàng phục vụ bất cứ khi nào. Cam kết tài sản được chuyển đến nhà mới nhanh chóng, an toàn với mức phí rẻ và không phát sinh ngoài hợp đồng.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/hai-minh-express.appspot.com/o/services%2Fchuyen-nha.png?alt=media&token=d810a437-40a2-4ead-9b75-4297e9fc575d'
    },
    {
      icon: 'flaticon-car',
      title: 'Chuyển Văn Phòng',
      description: `Thủ tục đơn giản, công tác đóng gói, di dời diễn ra nhanh chóng và cẩn thận. Đảm
      bảo hoạt động kinh doanh, làm việc không bị gián đoạn.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/hai-minh-express.appspot.com/o/services%2Fchuyen-van-phong.png?alt=media&token=d2630108-7975-43cb-8d3d-335063f2ce05'
    },
    {
      icon: 'flaticon-frontal-truck',
      title: 'Di Dời Kho Xưởng',
      description: `Phương án di dời được xây dựng kỹ lưỡng, khoa học và tiết kiệm nhất. Đội ngũ
      tháo ráp, vận chuyển chuyên nghiệp, tay nghề cao. Thực hiện di dời an toàn trong
      thời gian ngắn nhất.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/hai-minh-express.appspot.com/o/services%2Fdi-doi-kho-xuong.png?alt=media&token=ae0d8124-7cca-4d12-82b2-10e48e0bea0c'
    },
    {
      icon: 'flaticon-platform',
      title: 'Bốc Xếp Hàng Hóa',
      description: `Với đội ngũ nhân viên trẻ khỏe, nhiệt tình luôn làm việc tận tâm sẽ mang đến cho
      bạn một dịch vụ tốt nhất. Giá cả cạnh tranh cũng là một trong những lý do bạn
      chọn dịch vụ của chúng tôi.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/hai-minh-express.appspot.com/o/services%2Fboc-xep-hang-hoa.png?alt=media&token=599a5216-d0fa-4063-879e-55b9affa3523'
    },
    {
      icon: 'flaticon-platform',
      title: 'Cung Ứng Lao Động Phổ Thông Thời Vụ',
      description: `Bạn đang sở hữu một xưởng cơ khí, một kho với số lượng hàng hóa lớn? Bạn đang
      cần một lượng công nhân bốc xếp chuyên nghiệp có thể phụ bạn làm tất cả công
      việc khó khăn của bạn?`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/hai-minh-express.appspot.com/o/services%2Fcung-ung-lao-dong.png?alt=media&token=326d89e8-0b15-4c84-bcab-6023feb7dd8e'
    }
  ]
};

export default useConstants;
