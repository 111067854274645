export default {
  common: {
    appName: 'Vận Tải Hải Minh',
    latest: 'Mới nhất',
    popular: 'Phổ biến',
    oldest: 'Cũ nhất',
    save: 'Lưu',
    search: 'Tìm kiếm',
    logout: 'Đăng xuất'
  },

  route: {
    home: 'Trang chủ',
    about: 'Giới thiệu',
    aboutUs: 'Giới thiệu',
    blog: 'Tin tức',
    services: 'Dịch vụ',
    contact: 'Liên hệ',
    contactUs: 'Liên hệ',
    statistic: 'Thống kê'
  },

  blog: {
    newPost: 'Thêm mới',
    editPost: 'Chỉnh sửa'
  },

  login: {
    title: 'Đăng nhập',
    enter_details_below: 'Nhập thông tin vào bên dưới.',
    hi_welcome_back: 'Xin chào, Chào mừng trở lại',
    remember_me: 'Duy trì đăng nhập',
    email: 'Email',
    password: 'Mật khẩu'
  },

  contact: {
    title: 'Liên hệ',
    address: 'Địa Chỉ',
    phone: 'Số Điện Thoại',
    hotline: 'Số hotline',
    moreInfo: 'Thông Tin Thêm',
    taxCode: 'Mã Số Thuế',
    content:
      'Với kinh nghiệm nhiều năm hoạt động trong lĩnh vực vận chuyển. Vận tải Hải Minh hiện đã xây dựng nên chỗ đứng vững chắc trong lòng của hàng chục ngàn khách hàng cả nước. Chất lượng dịch vụ tốt, giá cả hợp lý. Thái độ phục vụ tận tình và luôn lắng nghe mong muốn của khách hàng,… Chính là những bí quyết để Vận tải Hải Minh trở thành thương hiệu vận chuyển được đánh giá hàng đầu hiện nay.',
    learnMore: 'Tìm hiểu thêm'
  },

  about: {
    ourHistory: ''
  }
};
