import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { query, collection, getDocs, orderBy } from 'firebase/firestore';
// material
import { Link } from '@mui/material';
//
import { db } from '../services/firebase';
import SiteFooter from '../layouts/SiteFooter';
import SiteNavbar from '../layouts/SiteNavbar';
import useRouteNames from '../utils/useRouteNames';
import { fToMarkdown } from '../utils/formatString';
import useStrings from '../utils/useStrings';

// ----------------------------------------------------------------------

export default function Blog() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const rowsPerPage = 9;

  const fetchPosts = async () => {
    try {
      const q = query(collection(db, 'posts'), orderBy('updatedAt', 'desc'));

      const querySnapshot = await getDocs(q);
      const _posts = [];
      querySnapshot.forEach((doc) => {
        _posts.push(doc.data());
      });
      setPosts(_posts);
      setMaxPage(Math.ceil(_posts.length / rowsPerPage));
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [navigate]);

  const getImageUrl = (content) => {
    const start = content.indexOf('https://firebasestorage');
    const end = content.indexOf('"', start);
    return content.substring(start, end);
  };

  const getMarkdown = (content) => {
    const markdown = fToMarkdown(content);
    if (markdown.includes('https://firebasestorage')) {
      return markdown.substring(markdown.indexOf(')') + 1);
    }
    return markdown;
  };

  return (
    <div>
      <SiteNavbar activeName={useRouteNames.blog} />
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: 'url(images/hero_bg_1.jpg)' }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8" data-aos="fade-up" data-aos-delay={400}>
              <h1 className="text-white font-weight-light text-uppercase font-weight-bold">
                {useStrings.route.blog}
              </h1>
              <p className="breadcrumb-custom">
                <a href={useRouteNames.home}>{useStrings.route.home}</a>{' '}
                <span className="mx-2">&gt;</span> <span>{useStrings.route.blog}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section">
        <div className="container">
          <div className="row">
            {posts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((post) => {
              const source = getImageUrl(post.content) || 'images/no_image.jpeg';
              const markdown = `${getMarkdown(post.content).substring(0, 162)}...`;
              return (
                <div key={post.id} className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                  <div className="h-entry">
                    <img
                      src={source}
                      alt={post.title}
                      // className="img-fluid"
                      width="100%"
                      height="300px"
                    />
                    <h2 className="font-size-regular">
                      <Link
                        color="#f89d1"
                        underline="hover"
                        to={`/${useRouteNames.blog}/${post.id}`}
                        component={RouterLink}
                      >
                        {post.title}
                      </Link>
                    </h2>
                    <div className="meta mb-4">
                      {/* Theresa Winston <span className="mx-2">•</span> Jan 18, 2019
                    <span className="mx-2">•</span> <a href="#">News</a> */}
                      {post.updatedAt.toDate().toLocaleDateString('vi-VN')}
                    </div>
                    <p>{markdown}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container text-center pb-5">
        <div className="row">
          <div className="col-12">
            <p className="custom-pagination">
              {[...Array(maxPage)].map((v, index) =>
                index === page ? (
                  <span key={index}>{index + 1}</span>
                ) : (
                  <a key={index} href="#" onClick={() => setPage(index)}>
                    {index + 1}
                  </a>
                )
              )}
            </p>
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
