import LocalizedStrings from 'react-localization';
import useConstants from './useConstants';
// import en from './locales/en';
import vi from './locales/vi';

const useStrings = new LocalizedStrings({
  // en,
  vi
});

useStrings.setLanguage(useConstants.language.vi);

export default useStrings;
