import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { query, collection, getDocs, orderBy, limit } from 'firebase/firestore';
// material
import { Link } from '@mui/material';
//
import useConstants from '../utils/useConstants';
import SiteFooter from '../layouts/SiteFooter';
import SiteNavbar from '../layouts/SiteNavbar';
import useRouteNames from '../utils/useRouteNames';
import { db } from '../services/firebase';
import { fToMarkdown } from '../utils/formatString';

// ----------------------------------------------------------------------

export default function Home() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    try {
      const q = query(collection(db, 'posts'), orderBy('updatedAt', 'desc'), limit(2));

      const querySnapshot = await getDocs(q);
      const _posts = [];
      querySnapshot.forEach((doc) => {
        _posts.push(doc.data());
      });
      setPosts(_posts);
    } catch (err) {
      console.error(err);
      alert('An error occured while fetching user data');
    }
  };

  const getImageUrl = (content) => {
    const start = content.indexOf('https://firebasestorage');
    const end = content.indexOf('"', start);
    return content.substring(start, end);
  };

  const getMarkdown = (content) => {
    const markdown = fToMarkdown(content);
    if (markdown.includes('https://firebasestorage')) {
      return markdown.substring(markdown.indexOf(')') + 1);
    }
    return markdown;
  };

  useEffect(() => {
    fetchPosts();
  }, [navigate]);

  return (
    <div>
      <SiteNavbar activeName={useRouteNames.home} />
      <div
        className="site-blocks-cover overlay"
        style={{ backgroundImage: 'url(images/hero_bg_1.jpg)' }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8" data-aos="fade-up" data-aos-delay={400}>
              <h1 className="text-white font-weight-light mb-5 text-uppercase font-weight-bold">
                VẬN TẢI HẢI MINH
              </h1>
              <h4 className="text-white font-weight-light mb-5 font-weight-bold">
                Trọn Gói - Chuyên Nghiệp - Nhanh Chóng
              </h4>
              {/* <p>
                <a href="#" className="btn btn-primary py-3 px-5 text-white">
                  Get Started!
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center no-gutters align-items-stretch overlap-section">
          <div className="col-md-2" />
          <div className="col-md-4">
            <div className="feature-1 pricing h-100 text-center">
              <div className="icon">
                <span className="icon-dollar" />
              </div>
              <h2 className="my-4 heading">Giá Tốt Nhất</h2>
              <p>Cam kết giá tốt nhất theo tiêu chí “Trọn Gói - Chuyên Nghiệp - Nhanh Chóng”</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-3 pricing h-100 text-center">
              <div className="icon">
                <span className="icon-phone" />
              </div>
              <h2 className="my-4 heading">Hỗ Trợ 24/7</h2>
              <p>
                Hãy gọi cho chúng tôi:{' '}
                <a className="text-white font-weight-light mb-5 font-weight-bold" href="#">
                  0367566949
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
      {/* <div className="site-section">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="mb-0 text-primary">What We Offer</h2>
              <p className="color-black-opacity-5">Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div className="row align-items-stretch">
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="unit-4 d-flex">
                <div className="unit-4-icon mr-4">
                  <span className="text-primary flaticon-travel" />
                </div>
                <div>
                  <h3>Air Freight</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis
                    molestiae vitae eligendi at.
                  </p>
                  <p className="mb-0">
                    <a href="#">Learn More</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="unit-4 d-flex">
                <div className="unit-4-icon mr-4">
                  <span className="text-primary flaticon-sea-ship-with-containers" />
                </div>
                <div>
                  <h3>Ocean Freight</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis
                    molestiae vitae eligendi at.
                  </p>
                  <p className="mb-0">
                    <a href="#">Learn More</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="unit-4 d-flex">
                <div className="unit-4-icon mr-4">
                  <span className="text-primary flaticon-frontal-truck" />
                </div>
                <div>
                  <h3>Ground Shipping</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis
                    molestiae vitae eligendi at.
                  </p>
                  <p className="mb-0">
                    <a href="#">Learn More</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="site-section block-13">
        {/* <div class="container"></div> */}
        <div className="owl-carousel nonloop-block-13">
          {useConstants.services.map((item) => (
            <div key={item.title}>
              <a href="#" className="unit-1 text-center">
                <img
                  src={item.image}
                  alt="img_1"
                  // className="img-fluid"
                  width="auto"
                  height="500px"
                />
                <div className="unit-1-text">
                  <h3 className="unit-1-heading">{item.title}</h3>
                  <p className="px-5">{item.description}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="site-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary">Các Dịch Vụ</h2>
              <p className="color-black-opacity-5">Chúng Tôi Cung Cấp Các Dịch Vụ Sau</p>
            </div>
          </div>
          <div className="row align-items-stretch">
            {useConstants.services.map((item) => (
              <div key={item.title} className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4">
                    <span className={`text-primary ${item.icon}`} />
                  </div>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    {/* <p>
                    <a href="#">Learn More</a>
                  </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div
        className="site-blocks-cover overlay inner-page-cover"
        style={{ backgroundImage: 'url(images/hero_bg_2.jpg)', backgroundAttachment: 'fixed' }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-7" data-aos="fade-up" data-aos-delay={400}>
              <a
                href="https://vimeo.com/channels/staffpicks/93951774"
                className="play-single-big mb-4 d-inline-block popup-vimeo"
              >
                <span className="icon-play" />
              </a>
              <h2 className="text-white font-weight-light mb-5 h1">
                View Our Services By Watching This Short Video
              </h2>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="site-section border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary">Testimonials</h2>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_3.jpg" alt="person_3" className="img-fluid mb-3" />
                  <p>John Smith</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_2.jpg" alt="person_2" className="img-fluid mb-3" />
                  <p>Christine Aguilar</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_4.jpg" alt="person_4" className="img-fluid mb-3" />
                  <p>Robert Spears</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
            <div>
              <div className="testimonial">
                <figure className="mb-4">
                  <img src="images/person_5.jpg" alt="person_5" className="img-fluid mb-3" />
                  <p>Bruce Rogers</p>
                </figure>
                <blockquote>
                  <p>
                    “Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                    reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga
                    beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde
                    iusto.”
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="site-section">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary">Tin Tức</h2>
              <p className="color-black-opacity-5">
                Xem Tin Tức Được Cập Nhật Hàng Ngày Của Chúng Tôi
              </p>
            </div>
          </div>
          <div className="row mb-3 align-items-stretch">
            {posts.map((post) => {
              const source = getImageUrl(post.content) || 'images/no_image.jpeg';
              const markdown = `${getMarkdown(post.content).substring(0, 162)}...`;
              return (
                <div key={post.id} className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                  <div className="h-entry">
                    <img src={source} alt={post.title} width="100%" height="300px" sizes="cover" />
                    <h2 className="font-size-regular">
                      <Link
                        color="#f89d1"
                        underline="hover"
                        to={`/${useRouteNames.blog}/${post.id}`}
                        component={RouterLink}
                      >
                        {post.title}
                      </Link>
                    </h2>
                    <div className="meta mb-4">
                      {/* Theresa Winston <span className="mx-2">•</span> Jan 18, 2019
                    <span className="mx-2">•</span> <a href="#">News</a> */}
                      {post.updatedAt.toDate().toLocaleDateString('vi-VN')}
                    </div>
                    <p>{markdown}</p>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
              <div className="h-entry">
                <img src="images/blog_1.jpg" alt="blog_1" className="img-fluid" />
                <h2 className="font-size-regular">
                  <a href="#">Warehousing Your Packages</a>
                </h2>
                <div className="meta mb-4">
                  by Theresa Winston <span className="mx-2">•</span> Jan 18, 2019 at 2:00 pm{' '}
                  <span className="mx-2">•</span> <a href="#">News</a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea
                  maiores sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a
                  eius.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
              <div className="h-entry">
                <img src="images/blog_2.jpg" alt="blog_2" className="img-fluid" />
                <h2 className="font-size-regular">
                  <a href="#">Warehousing Your Packages</a>
                </h2>
                <div className="meta mb-4">
                  by Theresa Winston <span className="mx-2">•</span> Jan 18, 2019 at 2:00 pm{' '}
                  <span className="mx-2">•</span> <a href="#">News</a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea
                  maiores sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a
                  eius.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="site-section border-top">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <h2 className="mb-5 text-black">Try Our Services</h2>
              <p className="mb-0">
                <a href="booking.html" className="btn btn-primary py-3 px-5 text-white">
                  Get Started
                </a>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <SiteFooter />
    </div>
  );
}
