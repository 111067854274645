import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// pages
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/dashboard/DashboardApp';
import DashboardBlog from './pages/dashboard/DashboardBlog';
import DashboardPost from './pages/dashboard/DashboardPost';

import NotFound from './pages/Page404';
import Home from './pages/Home';
import Blog from './pages/Blog';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import useRouteNames from './utils/useRouteNames';
import Post from './pages/Post';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: `/${useRouteNames.dashboard}`,
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: useRouteNames.blog, element: <DashboardBlog /> },
        { path: `${useRouteNames.blog}/*`, element: <DashboardPost /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: useRouteNames.about, element: <About /> },
        { path: useRouteNames.blog, element: <Blog /> },
        { path: `${useRouteNames.blog}/*`, element: <Post /> },
        { path: useRouteNames.contact, element: <Contact /> },
        { path: useRouteNames.services, element: <Services /> },
        { path: useRouteNames.login, element: <Login /> },
        { path: useRouteNames.register, element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
