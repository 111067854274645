export class Post {
  constructor(id, title, content, status, createdAt, updatedAt) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  toString() {
    return `${this.id}, ${this.title}, ${this.content}, ${this.status}, ${this.createdAt}, ${this.updatedAt}`;
  }
}

// Firestore data converter
export const postConverter = {
  toFirestore: (post) => ({
    id: post.id,
    title: post.title,
    content: post.content,
    status: post.status,
    createdAt: post.createdAt,
    updatedAt: post.updatedAt
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Post(data.id, data.title, data.content, data.status, data.createdAt, data.updatedAt);
  }
};
